import { template as template_b943a0f2b92849d5a388532a2b74aebf } from "@ember/template-compiler";
const FKLabel = template_b943a0f2b92849d5a388532a2b74aebf(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
