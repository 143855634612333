import { template as template_ff01f26b03364296942ac9a356473877 } from "@ember/template-compiler";
const WelcomeHeader = template_ff01f26b03364296942ac9a356473877(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
