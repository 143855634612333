import { template as template_e750f98b1d2445e4b5a23fc7b829becb } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ActivityCell = template_e750f98b1d2445e4b5a23fc7b829becb(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="activity"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="activity"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ActivityCell;
